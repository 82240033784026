import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import styles from './terms-conditions.module.scss';

export default ({ data }) => {
  return (
    <Layout>
      <div className={'columns'}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contentbox}`}>
            <h1 className={`title`}>Terms of Use</h1>
            <h2 className={`title is-5 has-text-info`}>
              Acceptance of the Terms of Use
            </h2>
            <p className={`content is-size-7`}>
              These terms of use are entered into by and between You and the
              family of companies comprised of Ingenovis Health; Fastaff, LLC;
              Stella.ai; Trustaff Management, LLC; U.S. Nursing Corporation;
              CardioSolution, LLC, and their subsidiaries and affiliates
              (collectively, the “Company” or “we” or “our”). The following
              terms and conditions, together with any documents they expressly
              incorporate by reference (collectively, these “Terms of Use”),
              govern your access to and use of IngenovisHealth.com, Fastaff.com,
              usnursing.com, myportal.fastaff.com, fastaff.erecruitcloud.com,
              Trustaff.com, My.Trustaff.com, Estaff365.com,
              mobile.er.Trustaff.com, cardiosolution.com and any content,
              functionality, and services offered on or through these websites
              (collectively, the “Websites”), whether as a guest or a registered
              user.
            </p>
            <p className={`content is-size-7`}>
              Please read the Terms of Use carefully before you start to use any
              of the Websites.{' '}
              <strong>
                By entering your information and clicking “submit,” you accept
                and agree to be bound and abide by these Terms of Use and our{' '}
                <Link to="/privacy-policy/">Privacy Policy</Link> incorporated
                into these Terms of Use by this reference.
              </strong>{' '}
              If you do not want to agree to these Terms of Use or the Privacy
              Policy, you must not access or use the any of the Websites.
            </p>
            <p className={`content is-size-7`}>
              The Websites are offered and available to users who are 18 years
              of age or older and reside in the United States or any of its
              territories or possessions. By using the Websites, you represent
              and warrant that you are of legal age to form a binding contract
              with Ingenovis Health, Fastaff, Stella, CardioSolution, or
              Trustaff, and meet all of these eligibility requirements. If you
              do not meet all of these requirements, you must not access or use
              the Websites.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Changes to the Terms of Use
            </h2>
            <p className={`content is-size-7`}>
              We may revise and update these Terms of Use from time to time in
              our sole discretion. All changes are effective immediately when we
              post them, and apply to all access to and use of the Websites
              thereafter. However, any changes to the dispute resolution
              provisions set out in Governing Law and Jurisdiction will not
              apply to any disputes for which the parties have actual notice
              before the date the change is posted on the Websites.
            </p>
            <p className={`content is-size-7`}>
              Your continued use of the Websites following the posting of
              revised Terms of Use means that you accept and agree to the
              changes. You are expected to check this page from time to time so
              you are aware of any changes, as they are binding on you.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Accessing the Websites and Account Security
            </h2>
            <p className={`content is-size-7`}>
              We reserve the right to withdraw, discontinue, or amend the
              Websites, and any service or material we provide on the Websites,
              in our sole discretion without notice. We will not be liable if
              for any reason all or any part of the Websites is unavailable at
              any time or for any period. From time to time, we may restrict
              access to some parts of one or more of Websites, or an entire
              Website, to users, including registered users.
            </p>
            <p className={`content is-size-7`}>
              To access one of the Websites or some of the resources it offers,
              you may be asked to provide certain information. It is a condition
              of your use of the Websites that all the information you provide
              on the Websites is correct, current, and complete. You agree that
              all information you provide to register or use the Websites,
              including through the use of any interactive features on the
              Websites, is governed by our{' '}
              <Link to="/privacy-policy/">Privacy Policy</Link>, and you consent
              to all actions we take with respect to your information consistent
              with our <Link to="/privacy-policy/">Privacy Policy</Link>.
            </p>
            <p className={`content is-size-7`}>
              If you choose, or are provided with, a user name, password, or any
              other piece of information as part of our security procedures, you
              must treat such information as confidential, and you must not
              disclose it to any other person or entity. You also acknowledge
              that your account is personal to you and agree not to provide any
              other person with access to the Websites or portions of them using
              your user name, password, or other security information. You agree
              to notify us immediately of any unauthorized access to or use of
              your user name or password or any other breach of security. You
              also agree to ensure that you exit from your account at the end of
              each session. You should use particular caution when accessing
              your account from a public or shared computer so that others are
              not able to view or record your password or other personal
              information.
            </p>
            <p className={`content is-size-7`}>
              We have the right to disable any user name, password, or other
              identifier, whether chosen by you or provided by us, at any time
              in our sole discretion for any or no reason, including if, in our
              opinion, you have violated any provision of these Terms of Use.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Intellectual Property Rights
            </h2>
            <p className={`content is-size-7`}>
              The Websites and their entire contents, features, and
              functionality (including but not limited to all information,
              software, text, displays, images, video, and audio, and the
              design, selection, and arrangement thereof), are owned by
              Ingenovis Health, Fastaff, Stella, CardioSolution, and Trustaff
              and its licensors, or other providers of such material and are
              protected by United States and international copyright, trademark,
              patent, trade secret, and other intellectual property or
              proprietary rights laws.
            </p>
            <p className={`content is-size-7`}>
              These Terms of Use permit you to use the Websites for your
              personal, non-commercial use only, except that if you are an
              authorized representative of a hospital client of Fastaff or
              Trustaff, you may use the “I am a Hospital” pages of the Websites
              for the purpose of making and managing staffing requests for your
              hospital. You must not reproduce, distribute, modify, create
              derivative works of, publicly display, publicly perform,
              republish, download, store, or transmit any of the material on our
              Websites, except as follows:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                Your computer may temporarily store copies of such materials in
                RAM incidental to your accessing and viewing those materials.
              </li>
              <li>
                You may store files that are automatically cached by your Web
                browser for display enhancement purposes.
              </li>
              <li>
                You may print or download one copy of a reasonable number of
                pages of the Websites for your own personal, non-commercial use
                (and in the case of hospital clients of Fastaff, for the limited
                purposes set forth in the preceding paragraph) and not for
                further reproduction, publication, or distribution.
              </li>
              <li>
                For any desktop, mobile, or other applications we provide for
                download, you may download a single copy to your computer or
                mobile device solely for your own personal, non-commercial use,
                provided you agree to be bound by our end user license agreement
                for such applications.
              </li>
              <li>
                If we provide social media features with certain content, you
                may take such actions as are enabled by such features.
              </li>
            </ul>
            <p className={`content is-size-7`}>You must not:</p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>Modify copies of any materials from the Websites.</li>
              <li>
                Use any illustrations, photographs, video or audio sequences, or
                any graphics separately from the accompanying text.
              </li>
              <li>
                Delete or alter any copyright, trademark, or other proprietary
                rights notices from copies of materials from the Websites.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              You must not access or use for any commercial purposes any part of
              the Websites or any services or materials available through the
              Websites, except as expressly authorized in these Terms of Use.
            </p>
            <p className={`content is-size-7`}>
              If you print, copy, modify, download, or otherwise use or provide
              any other person with access to any part of the Websites in breach
              of the Terms of Use, your right to use the Websites will stop
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made. No right, title, or
              interest in or to the Websites or any content on the Websites is
              transferred to you, and all rights not expressly granted are
              reserved by Ingenovis Health, Fastaff, Stella, CardioSolution, and
              Trustaff. Any use of the Websites not expressly permitted by these
              Terms of Use is a breach of these Terms of Use and may violate
              copyright, trademark, and other laws.
            </p>
            <h2 className={`title is-5 has-text-info`}>Trademarks</h2>
            <p className={`content is-size-7`}>
              The Ingenovis Health, Fastaff, U.S. Nursing, Stella,
              CardioSolution, and Trustaff names and logos, any licensor or
              affiliate names and logos referenced on the Websites, and all
              related names, logos, product and service names, designs, and
              slogans are trademarks of Ingenovis Health, Fastaff, U.S. Nursing,
              Stella, CardioSolution and Trustaff, or their affiliates or
              licensors. You must not use such marks without the prior written
              permission of Ingenovis Health, Fastaff, U.S. Nursing, Stella,
              CardioSolution, or Trustaff, as appropriate. All other names,
              logos, product and service names, designs, and slogans on the
              Websites are the trademarks of their respective owners.
            </p>
            <h2 className={`title is-5 has-text-info`}>Prohibited Uses</h2>
            <p className={`content is-size-7`}>
              You may use the Websites only for lawful purposes and in
              accordance with these Terms of Use. You agree not to use the
              Websites:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                In any way that violates any applicable federal, state, local,
                or international law or regulation (including, without
                limitation, any laws regarding the export of data or software to
                and from the US or other countries).
              </li>
              <li>
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content, asking for personally identifiable information, or
                otherwise.
              </li>
              <li>
                To send, knowingly receive, upload, download, use, or re-use any
                material that does not comply with the Content Standards set out
                in Content Standards in these Terms of Use.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail”, “chain letter”,
                “spam”, or any other similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate Ingenovis Health,
                Fastaff, U.S. Nursing, Stella, CardioSolution, or Trustaff, an
                Ingenovis Health, Fastaff, U.S. Nursing, Stella, CardioSolution,
                or Trustaff employee, another user, or any other person or
                entity (including, without limitation, by using email addresses
                or screen names associated with any of the foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of the Websites, or which, as
                determined by us, may harm Ingenovis Health, Fastaff, U.S.
                Nursing, Stella, CardioSolution or Trustaff or users of the
                Websites or expose them to liability.
              </li>
            </ul>
            <p className={`content`}>Additionally, you agree not to:</p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                Use the Websites in any manner that could disable, overburden,
                damage, or impair one or more of the sites or interfere with any
                other party’s use of any of the Websites, including their
                ability to engage in real time activities through the Websites.
              </li>
              <li>
                Use any robot, spider, or other automatic device, process, or
                means to access the Websites for any purpose, including
                monitoring or copying any of the material on the Websites.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on
                the Websites or for any other unauthorized purpose without our
                prior written consent.
              </li>
              <li>
                Use any device, software, or routine that interferes with the
                proper working of the Websites.
              </li>
              <li>
                Introduce any viruses, Trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Websites, the server on which the
                Websites are stored, or any server, computer, or database
                connected to the Websites.
              </li>
              <li>
                Attack any of the Websites via a denial-of-service attack or a
                distributed denial-of-service attack.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of any of
                the Websites.
              </li>
            </ul>
            <h2 className={`title is-5 has-text-info`}>User Contributions</h2>
            <p className={` is-size-7`}>
              The Websites may contain features (“Interactive Services”) that
              allow users to post, submit, publish, display, or transmit to
              others content or materials (collectively, “User Contributions”)
              on or through the Websites.
            </p>
            <p className={`content is-size-7`}>
              All User Contributions must comply with the Content Standards set
              out in these Terms of Use.
            </p>
            <p className={`content is-size-7`}>
              Any User Contribution you post to the site will be considered
              non-confidential and non-proprietary. By providing any User
              Contribution on the Websites, you grant us and our affiliates and
              service providers, and each of their and our respective licensees,
              successors, and assigns the right to use, reproduce, modify,
              perform, display, distribute, and otherwise disclose to third
              parties any such material for any purpose.
            </p>
            <p className={`content is-size-7`}>
              You represent and warrant that:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                You own or control all rights in and to the User Contributions
                and have the right to grant the license granted above to us and
                our affiliates and service providers, and each of their and our
                respective licensees, successors, and assigns.
              </li>
              <li>
                All of your User Contributions do and will comply with these
                Terms of Use.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              You understand and acknowledge that you are responsible for any
              User Contributions you submit or contribute, and you, not
              Ingenovis Health, Fastaff, U.S. Nursing, Stella, CardioSolution,
              or Trustaff, have full responsibility for such content, including
              its legality, reliability, accuracy, and appropriateness.
            </p>
            <p className={`content is-size-7`}>
              We are not responsible or liable to any third party for the
              content or accuracy of any User Contributions posted by you or any
              other user of the Websites.
            </p>

            <h2 className={`title is-5 has-text-info`}>
              Monitoring and Enforcement; Termination
            </h2>
            <p className={`content is-size-7`}>We have the right to:</p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                Remove or refuse to post any User Contributions for any or no
                reason in our sole discretion.
              </li>
              <li>
                Take any action with respect to any User Contribution that we
                deem necessary or appropriate in our sole discretion, including
                if we believe that such User Contribution violates the Terms of
                Use, including the Content Standards, infringes any intellectual
                property right or other right of any person or entity, threatens
                the personal safety of users of the Websites or the public, or
                could create liability for Ingenovis Health, Fastaff, U.S.
                Nursing, Stella, CardioSolution or Trustaff.
              </li>
              <li>
                Disclose your identity or other information about you to any
                third party who claims that material posted by you violates
                their rights, including their intellectual property rights or
                their right to privacy.
              </li>
              <li>
                Take appropriate legal action, including without limitation,
                referral to law enforcement, for any illegal or unauthorized use
                of the Websites.
              </li>
              <li>
                Terminate or suspend your access to all or part of any of the
                Websites for any or no reason, including without limitation, any
                violation of these Terms of Use.
              </li>
            </ul>
            <p className={`content is-size-7`}>
              Without limiting the foregoing, we have the right to fully
              cooperate with any law enforcement authorities or court order
              requesting or directing us to disclose the identity or other
              information of anyone posting any materials on or through the
              Websites. YOU WAIVE AND HOLD HARMLESS INGENOVIS HEALTH, FASTAFF,
              U.S. NURSING, STELLA, CARDIOSOLUTION, or TRUSTAFF, AND THEIR
              AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS
              RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
              DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER
              SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <p className={`content is-size-7`}>
              However, we do not undertake to review material before it is
              posted on the Websites, and cannot ensure prompt removal of
              objectionable material after it has been posted. Accordingly, we
              assume no liability for any action or inaction regarding
              transmissions, communications, or content provided by any user or
              third party. We have no liability or responsibility to anyone for
              performance or nonperformance of the activities described in this
              section.
            </p>
            <h2 className={`title is-5 has-text-info`}>Content Standards</h2>
            <p className={`content is-size-7`}>
              These content standards apply to any and all User Contributions
              and use of Interactive Services. User Contributions must in their
              entirety comply with all applicable federal, state, local, and
              international laws and regulations. Without limiting the
              foregoing, User Contributions must not:
            </p>
            <ul className={`is-size-7 ${styles.bullets}`}>
              <li>
                Contain any material that is defamatory, obscene, indecent,
                abusive, offensive, harassing, violent, hateful, inflammatory,
                or otherwise objectionable.
              </li>
              <li>
                Promote sexually explicit or pornographic material, violence, or
                discrimination based on race, sex, religion, nationality,
                disability, sexual orientation, or age.
              </li>
              <li>
                Infringe any patent, trademark, trade secret, copyright, or
                other intellectual property or other rights of any other person.
              </li>
              <li>
                Violate the legal rights (including the rights of publicity and
                privacy) of others or contain any material that could give rise
                to any civil or criminal liability under applicable laws or
                regulations or that otherwise may be in conflict with these
                Terms of Use and our Privacy Policy.
              </li>
              <li>Be likely to deceive any person.</li>
              <li>
                Promote any illegal activity, or advocate, promote, or assist
                any unlawful act.
              </li>
              <li>
                Cause annoyance, inconvenience, or needless anxiety or be likely
                to upset, embarrass, alarm, or annoy any other person.
              </li>
              <li>
                Impersonate any person, or misrepresent your identity or
                affiliation with any person or organization.
              </li>
              <li>
                Involve commercial activities or sales, such as contests,
                sweepstakes and other sales promotions, barter, or advertising.
              </li>
              <li>
                Give the impression that they emanate from or are endorsed by us
                or any other person or entity, if this is not the case.
              </li>
            </ul>
            <h2 className={`title is-5 has-text-info`}>
              Copyright Infringement
            </h2>
            <p className={`content is-size-7`}>
              If you believe that any User Contributions violate your copyright,
              please see our Copyright Policy for instructions on sending us a
              notice of copyright infringement. It is the policy of Fastaff to
              terminate the user accounts of repeat infringers.
            </p>
            <h2 className={`title is-5 has-text-info`}>Document Repository</h2>
            <p className={`content is-size-7`}>
              Ingenovis Health, Fastaff, U.S. Nursing, Stella, CardioSolution,
              and Trustaff provide nurses (whether or not employed by Ingenovis
              Health, Fastaff, U.S. Nursing, Stella, CardioSolution, or Trustaff
              ) with the ability to upload documents using the Websites or the
              Fastaff Mobile Application and store the documents in a
              cloud-based repository (the “Repository”). Nurses who use the
              Repository should use it solely to upload and store professional
              documents related to nursing Users of the Repository are solely
              responsible for the accuracy, completeness and legality of all
              documents they store in the Repository and must comply with the
              Content Standards with regard to all materials uploaded or stored
              in the Repository. Users shall be solely responsible for keeping
              backup copies of and updating documents in the Repository and for
              any consequences arising from the use or distribution of their
              documents in the Repository. Company reserves the right to limit
              the amount of storage capacity available to each user and to
              discontinue or alter the use of the Repository at any time for any
              reason or no reason. Further information on security and privacy
              relating to the Repository is provided in our Privacy Policy.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Reliance on Information Posted
            </h2>
            <p className={`content is-size-7`}>
              We take reasonable steps to ensure that the information presented
              on or through the Websites is accurate. However, we do not warrant
              the accuracy, completeness, or usefulness of this information. Any
              reliance you place on such information is strictly at your own
              risk. We disclaim all liability and responsibility arising from
              any reliance placed on such materials by you or any other visitor
              to the Websites, or by anyone who may be informed of any of its
              contents.
            </p>
            <p className={`content is-size-7`}>
              The Websites may include content provided by third parties,
              including materials provided by other users, bloggers, and
              third-party licensors, syndicators, aggregators, and/or reporting
              services. All statements and/or opinions expressed in these
              materials, and all articles and responses to questions and other
              content, other than the content provided by Fastaff, are solely
              the opinions and the responsibility of the person or entity
              providing those materials. These materials do not necessarily
              reflect the opinion of Fastaff. We are not responsible, or liable
              to you or any third party, for the content or accuracy of any
              materials provided by any third parties.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Changes to the Websites
            </h2>
            <p className={`content is-size-7`}>
              We may update the content on the Websites from time to time, but
              their content is not necessarily complete or up-to-date. Any of
              the material on the Websites may be out of date at any given time,
              and we are under no obligation to update such material.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Information about You and Your Visits to the Websites
            </h2>
            <p className={`content is-size-7`}>
              All information we collect on the Websites is subject to our
              Privacy Policy. By using the Websites, you consent to all actions
              taken by us with respect to your information in compliance with
              the Privacy Policy.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Other Terms and Conditions
            </h2>
            <p className={`content is-size-7`}>
              Additional terms and conditions may also apply to specific
              portions, services or features of the Websites. All such
              additional terms and conditions are hereby incorporated by this
              reference into these Terms of Use.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Linking to the Websites
            </h2>
            <p className={`content is-size-7`}>
              No person or entity is permitted to link to any of the Websites
              without Ingenovis Health, Fastaff, U.S. Nursing, Stella,
              CardioSolution, or Trustaff's prior express written consent.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Links from the Websites
            </h2>
            <p className={`content is-size-7`}>
              If the Websites contain links to other sites and resources
              provided by third parties, these links are provided for your
              convenience only. This includes links contained in advertisements,
              including banner advertisements and sponsored links. We have no
              control over the contents of those sites or resources, and accept
              no responsibility for them or for any loss or damage that may
              arise from your use of them. If you decide to access any of the
              third-party websites linked to the Websites, you do so entirely at
              your own risk and subject to the terms and conditions of use for
              such websites.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Disclaimer of Warranties
            </h2>
            <p className={`content is-size-7`}>
              You understand that we cannot and do not guarantee or warrant that
              files available for downloading from the internet or the Websites
              will be free of viruses or other destructive code. You are
              responsible for implementing sufficient procedures and checkpoints
              to satisfy your particular requirements for anti-virus protection
              and accuracy of data input and output, and for maintaining a means
              external to our site for any reconstruction of any lost data. TO
              THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
              LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
              VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT
              YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
              PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITES OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES OR TO YOUR
              DOWNLOADING OF ANY MATERIAL POSTED ON THEM, OR ON ANY WEBSITE
              LINKED TO THEM.
            </p>
            <p className={`content is-size-7`}>
              YOUR USE OF THE WEBSITES, THEIR CONTENT, AND ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE WEBSITES IS AT YOUR OWN RISK. THE WEBSITES,
              THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              WEBSITES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
              WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
              NEITHER INGENOVIS HEALTH, FASTAFF, U.S. NURSING, STELLA,
              CARDIOSOLUTION, TRUSTAFF, NOR ANY PERSON ASSOCIATED WITH THEM
              MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
              COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
              AVAILABILITY OF THE WEBSITES. WITHOUT LIMITING THE FOREGOING,
              NEITHER INGENOVIS HEALTH, FASTAFF, U.S. NURSING, STELLA,
              CARDIOSOLUTION, TRUSTAFF, NOR ANYONE ASSOCIATED WITH THEM
              REPRESENTS OR WARRANTS THAT THE WEBSITES, THEIR CONTENT, OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES WILL BE ACCURATE,
              RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
              CORRECTED, THAT OUR SITES OR THE SERVERS THAT MAKES THEM AVAILABLE
              ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE
              WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES
              WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </p>
            <p className={`content is-size-7`}>
              TO THE FULLEST EXTENT PROVIDED BY LAW, FASTAFF AND U.S. NURSING
              HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
              IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
              WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
              PARTICULAR PURPOSE.
            </p>
            <p className={`content is-size-7`}>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Limitation on Liability
            </h2>
            <p className={`content is-size-7`}>
              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL INGENOVIS
              HEALTH, FASTAFF, U.S. NURSING, STELLA , CARDIOSOLUTION, OR
              TRUSTAFF OR THEIR AFFILIATES, OR THEIR LICENSORS, SERVICE
              PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR
              DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
              CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITES, ANY
              WEBSITES LINKED TO THEM, ANY CONTENT ON THE WEBSITES OR SUCH OTHER
              WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
              CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
              PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
              REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
              LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
              TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE,
              EVEN IF FORESEEABLE.
            </p>
            <p className={`content is-size-7`}>
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <h2 className={`title is-5 has-text-info`}>Indemnification</h2>
            <p className={`content is-size-7`}>
              You agree to defend, indemnify, and hold harmless Ingenovis
              Health, Fastaff, U.S. Nursing, Stella, CardioSolution, Trustaff,
              their affiliates, licensors, and service providers, and their
              respective officers, directors, employees, contractors, agents,
              licensors, suppliers, successors, and assigns from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses, or fees (including reasonable attorneys’ fees) arising
              out of or relating to your violation of these Terms of Use or your
              use of the Websites, including, but not limited to, your User
              Contributions, your use of the Repository, any use of the
              Websites’ content, services, and products other than as expressly
              authorized in these Terms of Use or your use of any information
              obtained from the Websites.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Governing Law and Jurisdiction
            </h2>
            <p className={`content is-size-7`}>
              All matters relating to the Websites and these Terms of Use and
              any dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              the State of Colorado without giving effect to any choice or
              conflict of law provision or rule (whether of the State of
              Colorado or any other jurisdiction).
            </p>
            <p className={`content is-size-7`}>
              Any legal suit, action, or proceeding arising out of, or related
              to, these Terms of Use or the Websites shall be instituted
              exclusively in the federal courts of the United States or the
              courts of the State of Colorado in each case located in the City
              and County of Denver. You waive any and all objections to the
              exercise of jurisdiction over you by such courts and to venue in
              such courts.
            </p>
            <h2 className={`title is-5 has-text-info`}>Arbitration</h2>
            <p className={`content is-size-7`}>
              At Fastaff and Trustaff's sole discretion, it may require You to
              submit any disputes arising from the use of these Terms of Use or
              the Websites, including disputes arising from or concerning their
              interpretation, violation, invalidity, non-performance, or
              termination, to final and binding arbitration under the Rules of
              Arbitration of the American Arbitration Association applying
              Colorado law.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Limitation on Time to File Claims
            </h2>
            <p className={`content is-size-7`}>
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
              RELATING TO THESE TERMS OF USE OR THE WEBSITES MUST BE COMMENCED
              WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE,
              SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
            </p>
            <h2 className={`title is-5 has-text-info`}>
              Waiver and Severability
            </h2>
            <p className={`content is-size-7`}>
              No waiver of by the company of any term or condition set out in
              these Terms of Use shall be deemed a further or continuing waiver
              of such term or condition or a waiver of any other term or
              condition, and any failure of Ingenovis Health, Fastaff, U.S.
              Nursing, Stella, CardioSolution, or Trustaff to assert a right or
              provision under these Terms of Use shall not constitute a waiver
              of such right or provision.
            </p>
            <p className={`content is-size-7`}>
              If any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal or
              unenforceable for any reason, such provision shall be eliminated
              or limited to the minimum extent such that the remaining
              provisions of the Terms of Use will continue in full force and
              effect.
            </p>
            <h2 className={`title is-5 has-text-info`}>Entire Agreement</h2>
            <p className={`content is-size-7`}>
              The Terms of Use, our Privacy Policy and any other provisions
              expressly linked to the Terms of Use constitute the sole and
              entire agreement between you and Ingenovis Health, Fastaff, U.S.
              Nursing, Stella, CardioSolution, and Trustaff regarding the
              Websites and supersede all prior and contemporaneous
              understandings, agreements, representations, and warranties, both
              written and oral, regarding the Websites.
            </p>
            <p className={`content is-size-7`}>
              Updated effective date: 9-15-2021
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
